// TournamentHome.js

import React, {useState,useEffect} from 'react';
import {useParams, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolleyball,faComments, faRankingStar,faBars, faHome, faLayerGroup, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy, faMedal, faShuffle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from "../config/default.json";
import { useTournament } from './TournamentContext';

const base_url = config.base_url;


const TournamentHome = () => {
  const { tournamentID } = useTournament();
  // const { linkindex } = useParams();
  // const [tournament, setTournament] = useState([]);

  // const fetchTournament = async () => {
  //   try {
  //     const response = await axios.get(base_url+`/api/tournaments/${linkindex}`);
  //     setTournament(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTournament();
  // }, []);



  return ( 
    <div className="home">
      {/* <h1 className="rainbow-text"> 
          <br/>
          {linkindex.toUpperCase()} Tournament 2023
    </h1> */}
      <h1>Scorecard </h1>
      <h3>Welcome to the home page of the Scorecard.</h3>
      <br/>
      <h2 className="home-button">
      <Link to= "./teams" >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Teams
          </Link>
          <br />
          <br />
          <Link to="./schedules" >
            <FontAwesomeIcon icon={faCalendarPlus} />
            &nbsp; Schedules
          </Link>
          <br />
          <br />
          <Link to="./scores">
            <FontAwesomeIcon icon={faTrophy} />
            &nbsp; Scores
          </Link> 
          <br />
          <br /> 
          <Link to="./standings" >
            <FontAwesomeIcon icon={faRankingStar} />
            &nbsp; Standings
          </Link>  
          <br />
          <br /> 
          <Link to="./wildcard">
            <FontAwesomeIcon icon={faShuffle} />
            &nbsp; WildCard
          </Link>  
          <br />
          <br /> 
          <Link to="./playoff" >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Playoff
          </Link> 
          <br />
          <br /> 
          <Link to="./feedback" >
            <FontAwesomeIcon icon={faComments} />
            &nbsp; Feedback
          </Link>     
      </h2>
    
    </div>
  );
};

export default TournamentHome;
