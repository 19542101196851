import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy,faShuffle, faT } from '@fortawesome/free-solid-svg-icons';
import { useTournament } from './TournamentContext';


const base_url = config.base_url;

const WildCard = () => {
  const [games, setGames] = useState([]);
  const { tournamentID } = useTournament();


  useEffect(() => {
    const fetchAndSetGames = async () => {
      await fetchGames();
    };
  
    // Fetch data immediately when component mounts
    if(tournamentID){
    fetchAndSetGames();
    }
    // Set up an interval to fetch data every 30 seconds
    const interval = setInterval(fetchAndSetGames, 30000);
  
    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [tournamentID]);
  



const fetchGames = async () => {
  try {
    let url = base_url + `/api/wildcardgames/${tournamentID}`;
    const response = await axios.get(url);
    setGames(response.data);
  } 
  catch (error) {
    console.error(error);
  }
};



  return (
    <div>
       <h1>
        <FontAwesomeIcon icon={faShuffle} size="1x"/> &nbsp;
      WildCard Games</h1>
      <div>
   
        <br />
  
      </div>
      <div className="card-container">
        {games.map((game) => (
          <div key={game._id} className="card">
            <table>
              <thead></thead>
              <tbody>
                <tr>
                {game.court != 0 && (
                       <h3>
                       Court: {game.court}  
                       </h3>
                    )}  
                </tr>
                <tr>
                  <td
                    style={
                      game.scoreTeam1 > game.scoreTeam2
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam1 > game.scoreTeam2 && (
                      <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '5px' }} />
                    )}
                    {game.team1?.name || 'TBD'} 
                  </td>
                  <td
                    style={
                      game.scoreTeam1 > game.scoreTeam2
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam1}
                  </td>
                </tr>
                <tr>
                  <td
                    style={
                      game.scoreTeam2 > game.scoreTeam1
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam2 > game.scoreTeam1 && (
                      <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '5px' }} />
                    )}
                    {game.team2?.name || 'TBD'}
                  </td>
                  <td
                    style={
                      game.scoreTeam2 > game.scoreTeam1
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam2}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WildCard;
