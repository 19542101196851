import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/default.json';
import { useTournament } from './TournamentContext';

const base_url = config.base_url;

const MangeTeams = () => {
  const [name, setName] = useState('');
  const [captain, setCaptain] = useState('');
  const [state, setState] = useState('');
    const { tournamentID } = useTournament();
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [editTeam, setEditTeam] = useState({}); // State to store edited team data

  useEffect(() => {
    fetchTeams();
    fetchDivisions();
  }, [tournamentID]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(base_url + `/api/teams/${tournamentID}`);
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(base_url + `/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleAddTeam = async () => {
    try {
      await axios.post(base_url+'/api/teams', { tournamentID,name,captain,state, division: selectedDivision, });
      setName('');
      setCaptain('');
      setState('');
      setSelectedDivision('');
      fetchTeams();
      alert('Team added successfully!');
    } catch (error) {
      alert('Error: Team not added. '+ error.response.data.error);
      console.error('Failed to add team. '+ error.response.data.error);
    }
  };

  const handleEdit = (team) => {
    // Set the team data to be edited
    setEditTeam(team);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(base_url + `/api/teams/${editTeam._id}`, editTeam);
      // Reset editTeam state
      setEditTeam({});
      // Fetch updated team data
      fetchTeams();
    } catch (error) {
      console.error('Failed to update team', error);
    }
  };

  const handleDelete = async (teamId) => {
    try {
      await axios.delete(base_url + `/api/teams/${teamId}`);
      // Fetch updated team data
      fetchTeams();
    } catch (error) {
      console.error('Failed to delete team', error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleCaptainChange = (e) => {
    setCaptain(e.target.value);
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
  };
  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
  };

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setEditTeam((prevTeam) => ({
      ...prevTeam,
      [key]: value,
    }));
  };

  return (
    <div>
      <h2>Team Table</h2>
      <table>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Captain</th>
            <th>State</th>
            <th>Division</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team._id}>
              <td>
                <input
                  type="text"
                  value={editTeam._id === team._id ? editTeam.name : team.name}
                  onChange={(e) => handleInputChange(e, 'name')}
                  disabled={editTeam._id !== team._id}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={editTeam._id === team._id ? editTeam.captain : team.captain}
                  onChange={(e) => handleInputChange(e, 'captain')}
                  disabled={editTeam._id !== team._id}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={editTeam._id === team._id ? editTeam.state : team.state}
                  onChange={(e) => handleInputChange(e, 'state')}
                  disabled={editTeam._id !== team._id}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={editTeam._id === team._id ? editTeam.division.name : team.division.name}
                  onChange={(e) => handleInputChange(e, 'division')}
                  disabled={editTeam._id !== team._id}
                />
              </td>
              <td>
                {editTeam._id === team._id ? (
                  <React.Fragment>
                    <button onClick={handleUpdate}>Update</button>
                    <button onClick={() => setEditTeam({})}>Cancel</button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button onClick={() => handleEdit(team)}>Edit</button>
                    <button onClick={() => handleDelete(team._id)}>Delete</button>
                  </React.Fragment>
                )}
              </td>
            </tr>
          ))}
            <tr>
              <td>
                <input type="text" value={name} onChange={handleNameChange} />
              </td>
              <td>
                <input type="text" value={captain} onChange={handleCaptainChange} />
              </td>
              <td>
                <input type="text" value={state} onChange={handleStateChange} />
              </td>
              <td>
              <select value={selectedDivision} onChange={handleDivisionChange}>
            <option value="">Select Division</option>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
              </td>
              <td>
                <button onClick={handleAddTeam}>Add Team</button>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MangeTeams;
