// AddTeam.js

import React, { useState,useEffect } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";
const base_url = config.base_url;

const AddTeam = () => {
  const [name, setName] = useState('');
  const [captain, setCaptain] = useState('');
  const [state, setState] = useState('');
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID)
    fetchDivisions();
  }, [tournamentID]);

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(base_url+`/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleCaptainChange = (e) => {
    setCaptain(e.target.value);
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
  };
  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(base_url+'/api/teams', { tournamentID,name,captain,state, division: selectedDivision, });
      setName('');
      setCaptain('');
      setState('');
      setSelectedDivision('');
      alert('Team added successfully!');
    } catch (error) {
      alert('Error: Team not added. '+ error.response.data.error);
      console.error('Failed to add team. '+ error.response.data.error);
    }
  };

  return (
    <div>
    <h2>Add Team</h2>
    <form onSubmit={handleSubmit}>
      <label>
        Team Name*:<br />
        (Unique and Required):<br />
        <input type="text" value={name} onChange={handleNameChange}  required/>
      </label><br />
      <label>
        Captain Name:<br />
        <input type="text" value={captain} onChange={handleCaptainChange} />
      </label><br />
      <label>
        State Name:<br />
        <input type="text" value={state} onChange={handleStateChange} />
      </label><br />
      <br />
      <label>
          Division:<br />
          <select value={selectedDivision} onChange={handleDivisionChange}>
            <option value="">Select Division</option>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label><br />
        <br />
      <button type="submit">Add Team</button>
    </form>
  </div>
  
  );
};

export default AddTeam;
