import React, { createContext, useContext, useState,useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import axios from 'axios';
import config from "../config/default.json";
const base_url = config.base_url;
// Create a context with an initial value (null in this case)
const TournamentContext = createContext(null);



export function useTournament() {
  return useContext(TournamentContext);
}

export function TournamentProvider({ children }) {
  const [tournamentID, setTournamentID] = useState(null);
  const [linkindex, setLinkIndex] = useState(null);
 
  
  return (
    <TournamentContext.Provider value={{ tournamentID, setTournamentID, linkindex, setLinkIndex }}>
      {children}
    </TournamentContext.Provider>
  );
}
