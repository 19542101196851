import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTournament } from './TournamentContext';
import { faRankingStar,faBars, faHome, faLayerGroup, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy } from '@fortawesome/free-solid-svg-icons';

const base_url = config.base_url;

const Standings = () => {
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID){
      fetchTeams();
    // fetchDivisions();
    }
    // Set up an interval to fetch data every 10 seconds
    const interval = setInterval(fetchTeams, 10000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);

  }, [tournamentID]);

  const fetchTeams = async () => {
    try {
      let url = base_url + `/api/teams/${tournamentID}`;
      // if (selectedDivision) {
      //   url += `/division/${selectedDivision}`;
      // }
      const response = await axios.get(url);
      setTeams(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(base_url+ `/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
  };


  const calculateRank = () => {
    // Sort the teams based on criteria (you can adjust this as needed)
    const sortedTeams = teams.sort((a, b) => {
      if (a.totalWins === b.totalWins) {
        if (a.cumulativePointDifference === b.cumulativePointDifference) {
          // If points and point difference are the same, sort by totalPoints
          if (a.totalPoints === b.totalPoints) {
            // If totalPoints are also the same, sort by team name
            return a.name.localeCompare(b.name);
          }
          return b.totalPoints - a.totalPoints;
        }
        return b.cumulativePointDifference - a.cumulativePointDifference;
      }
      return b.totalWins - a.totalWins;
    });

    // Calculate the rank for each team
    return sortedTeams.map((team, index) => {
      return {
        ...team,
        rank: index + 1, // Add 1 to start ranks from 1
      };
    });
  };

  const rankedTeams = calculateRank();


  return (
    <div>
      <h1>
        <FontAwesomeIcon icon={faRankingStar} size="1x"/> &nbsp;
      Standings</h1>

      <div className="card-container">
        <div className="card">
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>P</th>
            <th>W</th>
            <th>L</th>
            {/* <th>diff</th>
            <th>tp</th> */}
          </tr>
        </thead>
        <tbody>
          {rankedTeams.map((team) => (
            <tr key={team._id}>
              <td>{team.rank}</td>
              <td>{team.name}</td>
              <td>{team.totalGamesPlayed}</td>
              <td>{team.totalWins}</td>
              <td>{team.totalLosses}</td>
              {/* <td>{team.cumulativePointDifference}</td>
              <td>{team.totalPoints}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
        </div>
       
      </div>

    </div>
  );
};

export default Standings;
