// Home.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy, faMedal } from '@fortawesome/free-solid-svg-icons';


const Admin = () => {
  return (
    <div className="home">
      <h1>Welcome VolleyTally Admin Page</h1>
      <p>This is the Admin page of the VolleyTally Application.</p>
      <h2 className="home-button">
      <Link to="/admin/add-tournament" >
            <FontAwesomeIcon icon={faPenToSquare} />
            &nbsp; Add Tournament
          </Link>
          <br />
          <br />
          <Link to="/admin/add-division" >
            <FontAwesomeIcon icon={faPlusSquare} />
            &nbsp; Add Division
          </Link>
          <br />
          <br />
          <Link to="/admin/add-team" >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Add Team
          </Link>
          <br />
          <br />
          <Link to="/admin/update-team" >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Update Team
          </Link>
          <br />
          <br />
          <Link to="/admin/add-game" >
            <FontAwesomeIcon icon={faGamepad} />
            &nbsp; Add Game
          </Link>
          <br />
          <br />
          <Link to="/admin/upload-game" >
            <FontAwesomeIcon icon={faGamepad} />
            &nbsp; Upload Game
          </Link>
          <br />
          <br />
          <Link to="/admin/add-score" >
            <FontAwesomeIcon icon={faTrophy} />
            &nbsp; Add Score
          </Link> 
          <br />
          <br />  
          <Link to="/admin/add-playoff" >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Add Playoff
          </Link>   
          <br />
          <br />  
          <Link to="/admin/add-playoffGame" >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Add New Playoff Game
          </Link>     
      </h2>
    </div>
  );
};

export default Admin;
