import React, { useEffect, useState } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRankingStar,faBars, faHome, faLayerGroup, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy } from '@fortawesome/free-solid-svg-icons';

const base_url = config.base_url;

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const { tournamentID } = useTournament();

  useEffect(() => {
    if(tournamentID){
    fetchTeams();
    fetchDivisions();
    }
  }, [tournamentID,selectedDivision]);

  const fetchTeams = async () => {
    try {
      let url = base_url + `/api/teams/${tournamentID}`;
      if (selectedDivision) {
        url += `/division/${selectedDivision}`;
      }
      const response = await axios.get(url);
      setTeams(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(base_url+`/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
  };

  return (
    <div>
        <h1>
        <FontAwesomeIcon icon={faUsers} size="1x"/> &nbsp;
         Teams</h1>
      <div>
      <h3>
        <label>
          Filter by Division:
          <select value={selectedDivision} onChange={handleDivisionChange}>
            <option value="">All</option>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label>
        </h3>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Captain</th>
            <th>State</th>
            <th>Div</th>
          </tr>
        </thead>
        <tbody>
          {teams.filter((team) => team.name != "BYE - Referee" &&  team.name != "BYE - Referee ")
            .map((team) => (
            <tr key={team._id}>
              <td>{team.name}</td>
              <td>{team.captain}</td>
              <td>{team.state}</td>
              <td>{team.division.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Teams;
