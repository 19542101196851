import React, { useState, useEffect } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";
const base_url = config.base_url;

const AddPlayoffGame = () => {
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedTeam1, setSelectedTeam1] = useState('');
  const [selectedTeam2, setSelectedTeam2] = useState('');
  const [selectedDivision, setSelectedDivision] = useState('');
  const [court, setCourt] = useState('');
  const [round, setRound] = useState('');
  const [rank, setRank] = useState('');
  const [time, setTime] = useState('');
  const [isWildCard, setisWildCard] = useState(false);
  const [scoreTeam1, setScoreTeam1] = useState('0');
  const [scoreTeam2, setScoreTeam2] = useState('0');
  const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID){
      fetchTeams();
      fetchDivisions();
    }
  }, [tournamentID]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(base_url + `/api/teams/${tournamentID}`);
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchTeamsByDivision = async (divisionId) => {
    try {
      const response = await axios.get(
        base_url + `/api/teams/${tournamentID}/division/${divisionId}`
      );
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(base_url + `/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleTeam1Change = (e) => {
    setSelectedTeam1(e.target.value);
  };

  const handleTeam2Change = (e) => {
    setSelectedTeam2(e.target.value);
  };

  const handleDivisionChange = (e) => {
    const divisionId = e.target.value;
    setSelectedDivision(divisionId);
    if(divisionId === "648d0b41fc7470eeb16ed7ab"){
      fetchTeams();
    } else{
      fetchTeamsByDivision(divisionId);
    }
  
  };

  const handleisWildCardChange = (e) => {
    const isChecked = e.target.checked;
    setisWildCard(isChecked);
  };
  
  const handleCourtChange = (e) => {
    setCourt(e.target.value);
  };

  const handleRoundChange = (e) => {
    setRound(e.target.value);
  };

  const handleRankChange = (e) => {
    setRank(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const handleScoreTeam1Change = (e) => {
    setScoreTeam1(e.target.value);
  };

  const handleScoreTeam2Change = (e) => {
    setScoreTeam2(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(base_url + `/api/playoffgames/`+tournamentID, {
        team1: selectedTeam1,
        team2: selectedTeam2,
        division: selectedDivision,
        round:round,
        rank:rank,
        court: court,
        time: time,
        isWildCard: isWildCard,
        scoreTeam1,
        scoreTeam2,
      });
      setSelectedTeam1('');
      setSelectedTeam2('');
      setSelectedDivision('');
      setisWildCard(false);
      setScoreTeam1('0');
      setScoreTeam2('0');
      setCourt('0');
      setRound('0');
      setRank('0'); 
      setTime('');
      alert('Playoff Game added successfully!');
     
    } catch (error) {
      console.error('Failed to add game', error);
    }
  };

  return (
    <div>
      <h2>Add Playoff Game</h2>
      <form onSubmit={handleSubmit}>
      <label>
          Division:<br />
          <select value={selectedDivision} onChange={handleDivisionChange}>
            <option value="">Select Division</option>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <br />
        <label>
          Team 1:<br />
          <select value={selectedTeam1} onChange={handleTeam1Change}>
            <option value="">Select Team</option>
            {teams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <br />
        <label>
          Team 2:<br />
          <select value={selectedTeam2} onChange={handleTeam2Change}>
            <option value="">Select Team</option>
            {teams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <br />
        <label className="feedback-label">
          Is WildCard Game:
          <input
            type="checkbox"
            checked={isWildCard}
            onChange={handleisWildCardChange} // Toggle the isWildCard value
          />
        </label>
   
        <label>
          Round:<br />
          <input type="number" value={round} onChange={handleRoundChange} />
        </label>
        <br />
        <br />
        <label>
          Rank:<br />
          <input type="number" value={rank} onChange={handleRankChange} />
        </label>
        <br />
        <br />
        <label>
          Court Number:<br />
          <input type="number" value={court} onChange={handleCourtChange} />
        </label>
        <br />
        <br />
        <label>
          Time:<br />
          <input type="time" value={time} onChange={handleTimeChange} />
        </label>
        <br />
        <br />
        <label>
          Score Team 1:<br />
          <input
            type="number"
            value={scoreTeam1}
            onChange={handleScoreTeam1Change}
          />
        </label>
        <br />
        <br />
        <label>
          Score Team 2:<br />
          <input
            type="number"
            value={scoreTeam2}
            onChange={handleScoreTeam2Change}
          />
        </label>
        <br />
        <br />
        <button type="submit">Add Game</button>
      </form>
    </div>
  );
};

export default AddPlayoffGame;
