import './Playoff.css';
import React, { useEffect, useState } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from '../config/default.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faMedal, faChampagneGlasses } from '@fortawesome/free-solid-svg-icons';

const base_url = config.base_url;

const Playoff = () => {
  const [games, setGames] = useState([]);
  const [round4Winner, setRound4Winner] = useState('');
const { tournamentID } = useTournament();



  useEffect(() => {
    if(tournamentID){
      fetchGames();
    }

    // Set up an interval to fetch data every 10 seconds
    const interval = setInterval(fetchGames, 10000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [tournamentID]);

  const fetchGames = async () => {
    try {
      const response = await axios.get(base_url + `/api/playoffgames/${tournamentID}`);
      const sortedGames = response.data.sort((a, b) => {
        // Sort by round first
        if (a.round !== b.round) {
          return a.round - b.round;
        }
        // Sort by rank within each round
        return a.rank - b.rank;
      });
      setGames(sortedGames);
      
      // Find the winner of round 4
      const round4Games = sortedGames.filter((game) => game.round === 4);
      const round4Game = round4Games.find((game) => game);
      //console.log(round4Game)
      if(round4Game && round4Game.scoreTeam1 != round4Game.scoreTeam2)
      {
        if (round4Game.scoreTeam1 > round4Game.scoreTeam2) {
          setRound4Winner(round4Game.team1.name || 'TBD');
        } else {
          setRound4Winner(round4Game.team2.name ||'TBD');
        }
      } else{
        setRound4Winner('TBD');
      }
     
    } catch (error) {
      console.error(error);
    }
  };


    

  
  const getTeam1ScoreClass = (team) => {
    if (team.scoreTeam1 > team.scoreTeam2) {
      return 'winner';
    } else if (team.scoreTeam1 < team.scoreTeam2) {
      return 'loser';
    }
    return '';
  };

  const getNextRoundGame = (teamName, round) => {
    const nextRound = round + 1;
    return games.find((game) => game.team1.name === teamName && game.round === nextRound);
  };

  return (
    <div>
      <br/>
    <div className="container">
      <h2>Playoff Bracket</h2>


       {/* Display the winner of round 4 */}
       {(round4Winner !== 'TBD' && round4Winner !== '') && (
          <div>
            <br/>
            <h2 style={{ color: 'rgb(28, 213, 34)'}} >
            Congratulations!!!  <br /> <br /> 
              <FontAwesomeIcon icon={faChampagneGlasses}  size="2x" style={{ color: 'purple', marginRight: '10px'}} />
             {round4Winner} &nbsp; 
              <FontAwesomeIcon icon={faChampagneGlasses} size="2x"  style={{ color: 'purple', marginRight: '10px'}} />
            </h2>
          </div>
        )}

    <div className="tournament">
      

      {Array.from({ length: 4 }, (_, roundIndex) => (
                <div key={`round-${roundIndex +1}`}>
          {games
            .filter((game) => game.round === roundIndex + 1)
            .map((game, gameIndex) => {
              const isTopWinner = game.scoreTeam1 > game.scoreTeam2;
              const isBottomWinner = game.scoreTeam2 > game.scoreTeam1;
              const isTopWinner4 = (game.scoreTeam1 > game.scoreTeam2 && game.round === 4);
              const isBottomWinner4 = (game.scoreTeam2 > game.scoreTeam1 && game.round === 4);
            
              return (
        <ul key={`round-${roundIndex + 1}-${game.rank}`} className={`round round-${roundIndex + 1}`}>

                  <li className="spacer"> &nbsp;</li>
                  <li className={`game game-top ${isTopWinner ? 'winner' : ''}`}>
                  {isTopWinner4 &&(
                       <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '5px' }} />
                    )} 
                    &nbsp; {game.team1?.name || 'TBD'} &nbsp;
                    {isTopWinner4 &&(
                       <FontAwesomeIcon icon={faMedal} style={{ marginRight: '5px' }} />
                    )}
                    <span>{game.scoreTeam1}</span>
                  </li>
                  
                  <li className="game game-spacer"> &nbsp; 
                  { game.court !== 0  && (
                    
                      <li className="game-court">Court: {game.court}</li>
                     
                    )}
                  </li>
                  
                  <li className={`game game-bottom ${isBottomWinner ? 'winner' : ''}`}>
                  {isBottomWinner4 && (
                       <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '5px' }} />  
                    )}  &nbsp;
                    {game.team2?.name || 'TBD'}
                    &nbsp;  
                    {isBottomWinner4 && (
                       <FontAwesomeIcon icon={faMedal} style={{ marginRight: '5px' }} />  
                    )}
                     <span>{game.scoreTeam2}</span>
                  </li>
                 
                </ul>
              );
            })}
        </div>
      ))}
    </div>
    </div>
    </div>
  );
};

export default Playoff;
