import React, { useState, useEffect } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";

const base_url = config.base_url;

const UpdateTeam = () => {
  const [teams, setTeams] = useState([]);
  const [updatedNames, setUpdatedNames] = useState({});
const [captainNames, setCaptainNames] = useState({});
const [stateNames, setStateNames] = useState({});
const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID)
    fetchTeams();
  }, [tournamentID]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(base_url + `/api/teams/${tournamentID}`);
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const handleTeamNameChange = (e, teamId) => {
    setUpdatedNames((prevNames) => ({
      ...prevNames,
      [teamId]: e.target.value,
    }));
  };

  const handleCaptainNameChange = (e, teamId) => {
    setCaptainNames((prevNames) => ({
      ...prevNames,
      [teamId]: e.target.value,
    }));
  };
  
  const handleStateNameChange = (e, teamId) => {
    setStateNames((prevNames) => ({
      ...prevNames,
      [teamId]: e.target.value,
    }));
  };
  

  const handleUpdateTeams = async () => {
    try {
      const updatedTeams = await Promise.all(
        teams.map(async (team) => {
          const updatedName = updatedNames[team._id];
          const updatedCaptainName = captainNames[team._id];
          const updatedStateName = stateNames[team._id];
  
          if (updatedName || updatedCaptainName || updatedStateName) {
            const response = await axios.put(`${base_url}/api/teams/${team._id}`, {
              name: updatedName || team.name,
              captain: updatedCaptainName || team.captain,
              state: updatedStateName || team.state,
            });
            return response.data;
          }
        })
      );
  
      alert('Teams updated successfully!');
    } catch (error) {
      alert('Error: Teams not updated.');
      console.error('Failed to update teams', error);
    }
  };
  

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Captain</th>
            <th>State</th>
            <th>Division</th>
          </tr>
        </thead>
            <tbody>
              {teams.map((team) => (
                <tr key={team._id}>
                  <td>
                    <input
                      type="text"
                      value={updatedNames[team._id] || team.name}
                      onChange={(e) => handleTeamNameChange(e, team._id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={captainNames[team._id] || team.captain}
                      onChange={(e) => handleCaptainNameChange(e, team._id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={stateNames[team._id] || team.state}
                      onChange={(e) => handleStateNameChange(e, team._id)}
                    />
                  </td>
                  <td>
                    {team.division.name}
                  </td>
                </tr>
              ))}
            </tbody>

      </table>
      <button onClick={handleUpdateTeams}>Update</button>
    </div>
  );
};

export default UpdateTeam;
