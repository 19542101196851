import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { useTournament } from './TournamentContext';


const base_url = config.base_url;

const Scores = () => {
  const [games, setGames] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [teams, setTeams] = useState([]);
const [selectedTeam, setSelectedTeam] = useState('');
const { tournamentID } = useTournament();


useEffect(() => {
  if(tournamentID){
    fetchDivisions();
    fetchTeams();
    fetchGames();
  }
 
  // Set up an interval to fetch data every 30 seconds
 // const interval = setInterval(fetchGames, 30000);

  // Clean up the interval when the component is unmounted
 // return () => clearInterval(interval);
}, [tournamentID,selectedDivision,selectedTeam]);


const fetchGames = async () => {
  try {
    if (!selectedTeam) {
      setGames([]); // If no team is selected, clear the games data
      return;
    }

    let url = base_url + `/api/games/team/${tournamentID}/${selectedTeam}`;
    const response = await axios.get(url);
    setGames(response.data);
  } catch (error) {
    console.error(error);
  }
};


const fetchTeams = async () => {
  try {

    if (!selectedDivision || selectedTeam) {
     // setTeams([]); // If no team is selected, clear the games data
      return;
    }

    let url = base_url + `/api/teams/${tournamentID}`;
    if (selectedDivision) {
      url += `/division/${selectedDivision}`;
    }
    const response = await axios.get(url);
    setTeams(response.data);
    if (selectedTeam === '' && response.data.length > 0) {
      setSelectedTeam(response.data[0]._id); // Set the selected team to the first ID in the list
    }
  } catch (error) {
    console.error('Failed to fetch teams', error);
  }
};

  

  const fetchDivisions = async () => {
    try {

      if (!selectedDivision){
        const response = await axios.get(base_url + `/api/divisions/${tournamentID}`);
        setDivisions(response.data);
        if (response.data.length > 0) {
          setSelectedDivision(response.data[0]._id); // Set the selected division to the first ID in the list
        }
      }
     
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
    setSelectedTeam('');
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };


  return (
    <div>
       <h1>
        <FontAwesomeIcon icon={faTrophy} size="1x"/> &nbsp;
      Scores</h1>
      <div>
      <h3>
        <br />
       <label>
          Division:
          <select value={selectedDivision} onChange={handleDivisionChange}>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label>
        &nbsp;  &nbsp;
          <label>
          Team:
              <select value={selectedTeam} onChange={handleTeamChange}>
                {teams.map((team) => (
                  <option key={team._id} value={team._id}>
                    {team?.name}
                  </option>
                ))}
              </select>
          </label>
          </h3>
      </div>
      <div className="card-container">
        {games.map((game) => (
          <div key={game._id} className="card">
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <td
                    style={
                      game.scoreTeam1 > game.scoreTeam2
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam1 > game.scoreTeam2 && (
                      <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '5px' }} />
                    )}
                    {game.team1?.name} 
                  </td>
                  <td
                    style={
                      game.scoreTeam1 > game.scoreTeam2
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam1}
                  </td>
                </tr>
                <tr>
                  <td
                    style={
                      game.scoreTeam2 > game.scoreTeam1
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam2 > game.scoreTeam1 && (
                      <FontAwesomeIcon icon={faTrophy} style={{ marginRight: '5px' }} />
                    )}
                    {game.team2?.name}
                  </td>
                  <td
                    style={
                      game.scoreTeam2 > game.scoreTeam1
                        ? { fontWeight: 'bold', color: 'purple', fontSize: '20px' }
                        : {}
                    }
                  >
                    {game.scoreTeam2}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Scores;
