import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { useTournament } from './TournamentContext';
import config from "../config/default.json";
const base_url = config.base_url;

const UploadData = () => {
  const [uploadedData, setUploadedData] = useState([]);
  const [tournamentID, setTournamentID] = useState(null);
  const { tournamentID: contextTournamentID } = useTournament();

  useEffect(() => {
    if (contextTournamentID) {
      setTournamentID(contextTournamentID);
    }
  }, [contextTournamentID]);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const fileData = reader.result;
        const parsedData = Papa.parse(fileData, { header: true }).data;
        setUploadedData(parsedData);
      };

      reader.readAsText(file);
    });
  };

  const handleUpload = async () => {
    try {
      if (tournamentID && uploadedData.length > 0) {
        await axios.post(base_url +`/api/uploadgames/${tournamentID}`, uploadedData);
        setUploadedData([]); // Clear uploaded data after successful upload
        alert('Data uploaded successfully!');   
      } else {
        alert('Please make sure a tournament is selected and/or data file is selected.');
      }
    } catch (error) {
      alert('Error: Failed to upload data. ' + error.response.data.error);
      console.error('Failed to upload data');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const generateTemplate = () => {
    const csvContent = "team1,team2,division,round,court,time,scoreTeam1,scoreTeam2,isReferee\n";
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "gamedata.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <h2>Upload Data</h2>
      <p>Use this button to download csv template file with hearder to use for uploading game data. Please 
        make sure teams and divisions referenced on this upload data already exists. </p>  
      <button onClick={generateTemplate}>Download Data Template</button>
      <br />
      <div {...getRootProps()} style={{ cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <br />
        <h3>Drag and drop an Excel or CSV file here, or click to select file</h3>
        <br />
      </div>
      <button onClick={handleUpload}>Upload Data</button>     
    </div>
  );
};

export default UploadData;
