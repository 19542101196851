import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { useTournament } from './TournamentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRankingStar,faBars, faHome, faLayerGroup, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy } from '@fortawesome/free-solid-svg-icons';
const base_url = config.base_url;


const Divisions = () => {
  const [divisions, setDivisions] = useState([]);
  const { tournamentID } = useTournament();



  const fetchDivisions = async (tournamentID) => {
    try {
      console.log(tournamentID);
      const response = await axios.get(base_url+`/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(tournamentID){
      fetchDivisions(tournamentID);
    }
   
  }, [tournamentID]);

  return (
    <div>
        <h1>
        <FontAwesomeIcon icon={faLayerGroup} size="1x"/> &nbsp;
      Divisions</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {divisions.map((division) => (
            <tr key={division._id}>
              <td>{division.name}</td>
              <td>{division.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Divisions;
