// AddTournament.js
import React, { useState } from 'react';
import axios from 'axios';
import config from "../config/default.json";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const base_url = config.base_url;

const AddTournament = () => {

  const [linkindex, setLinkIndex] = useState('');
  const [name, setName] = useState('');
  const [acronym, setAcronym] = useState('');
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [isPublished, setIsPublished] = useState(false);

  
  const handleLinkIndexChange = (e) => {
    setLinkIndex(e.target.value?.toLowerCase());
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAcronymChange = (e) => {
    setAcronym(e.target.value?.toUpperCase());
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };
  const handleIsPublishedChange = (e) => {
    const isChecked = e.target.checked;
    setIsPublished(isChecked);
  };
  



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (linkindex.includes(' ')) {
      alert('Name cannot contain spaces. You could use _ to replace space');
      return; // Prevent form submission
    }
  
  

    try {
      await axios.post(base_url +'/api/tournaments', { linkindex,name,acronym,year,description,location,isPublished });
      setLinkIndex('');
      setName('');
      setYear('');
      setAcronym('');
      setDescription('');
      setLocation('');
      alert('Tournament added successfully!');
    } catch (error) {
      console.error('Failed to add Tournament', error);
      alert('Failed to add Tournament'+error.response.data.error)
    }
  }

  return (
    <div>
        <h1>
        <FontAwesomeIcon icon={faPenToSquare} size="1x"/> &nbsp;
      Add Tournament</h1>
      <form onSubmit={handleSubmit}>
      <label>
          Link Index*:<br />
          (unique without spaces. You could combine acronym and year to make it unique for a yearly tournament.):<br />
          <input type="text" value={linkindex} onChange={handleLinkIndexChange} required />
        </label><br />
        <br />
        <label>
          Name*: 
          <br />
          <input type="text" value={name} onChange={handleNameChange} required />
        </label><br />
        <br />
        <label>
          Acronym*:<br />
          <input type="text" value={acronym} onChange={handleAcronymChange}  required/>
        </label><br />
        <br />
        <label>
          Year:<br />
          <input type="text" value={year} onChange={handleYearChange} />
        </label><br />
        <br />
        <label>
          Description:<br />
          <input type="text" value={description} onChange={handleDescriptionChange} />
        </label><br />
        <br />
        <label>
          Location:<br />
          <input type="text" value={location} onChange={handleLocationChange} />
        </label><br />
        <br />
        <label className="feedback-label">
          Is Published:
          <input
            type="checkbox"
            checked={isPublished}
            onChange={handleIsPublishedChange} // Toggle the isReferee value
          />
        </label>
        <br />
        <br />
        <button type="submit">Add Tournament</button>
      </form>
    </div>
  );
};

export default AddTournament;
