// AddDivision.js

import React, { useState } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";

const base_url = config.base_url;

const AddDivision = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { tournamentID } = useTournament();
  

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   

    try {

      if(tournamentID){
        await axios.post(base_url +'/api/divisions', { name,description,tournamentID });
        setName('');
        setDescription('');
        alert('Division added successfully!');
      }
    
    } catch (error) {
      console.error('Failed to add division', error);
      alert('Failed to add division. '+error.response.data.error);
    }
  };

  return (
    <div>
      <h2>Add Division</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Division Name:<br />
          <input type="text" value={name} onChange={handleNameChange} />
        </label><br />
        <br />
        <label>
          Division Description:<br />
          <input type="text" value={description} onChange={handleDescriptionChange} />
        </label><br />
        <br />
        <button type="submit">Add Division</button>
      </form>
    </div>
  );
};

export default AddDivision;
