import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useTournament } from './TournamentContext';
//import './../styles.css'; // Import your CSS file

const base_url = config.base_url;

const Feedback = () => {
  const [feedbackText, setFeedbackText] = useState('');
  const [name, setName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const { tournamentID } = useTournament();


  useEffect(() => {
    
    fetchFeedback();
  
  }, []);

  const fetchFeedback = async () => {
    try {
      const response = await axios.get(base_url + `/api/feedback/`);
      setFeedbackList(response.data);
    } catch (error) {
      console.error('Failed to fetch feedback', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedbackText.trim() === '') {
      alert('Error: Feedback text is empty');
      return;
    }

    try {
      await axios.post(base_url + `/api/feedback/`, { 
            text: feedbackText, 
            name: name,
            isPrivate: isPrivate,  });
      setFeedbackText('');
      setName('');
      setIsPrivate(false);
      alert('Feedback posted Successfully');
      fetchFeedback();
    } catch (error) {
      alert('Error: Feedback not added.');
      console.error('Failed to add Feedback', error);
    }
  };

  const textareaStyle = {
    height: `${window.innerHeight * 0.3}px`, // Adjust the multiplier as needed
    width: `${window.innerWidth * 0.6}px`, // Adjust the multiplier as needed
  };

  return (
    <div className="feedback-container">
      <h1>  <FontAwesomeIcon icon={faComments} size="1x"/> &nbsp; Feedback</h1>
      <br/>
      <h3>Please provide any feedback, suggestions for new features or improvement needed.</h3>
      <br />
      <form onSubmit={handleSubmit}>
        <label className="feedback-label">
          Feedback Text: <br/>
          <textarea
           style={textareaStyle}
            placeholder="Enter your feedback..."
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          ></textarea>
        </label>
        <label className="feedback-label">
          Name (optional):
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label className="feedback-label">
          Private Feedback:
          <input
            type="checkbox"
            checked={isPrivate}
            onChange={() => setIsPrivate(!isPrivate)} // Toggle the isPrivate value
          />
        </label>
        <button type="submit" className="feedback-button">
          Submit Feedback
        </button>
      </form>
      <br />
      <div className="feedback-list">
        <h2>Feedback Received:</h2>
        {feedbackList.map((feedback, index) => (
          <div key={index} className="feedback-card">
            <p className="feedback-name">{feedback.name || 'Anonymous'}:</p>
            <p className="feedback-text">{feedback.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Feedback;
