import './styles.css';
import React, { useState, useEffect } from 'react';
import { useMatch,BrowserRouter as Router, Routes, Route, Link, useLocation,ScrollRestoration } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging, faShuffle,faVolleyball,faBars, faHome, faLayerGroup, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy, faRankingStar, faMedal, faComments, faCopy } from '@fortawesome/free-solid-svg-icons';
import ReactGA from "react-ga4";
import { MainRoutes } from './routes/mainRoutes';
import axios from 'axios';
import config from "./config/default.json";
import { useTournament } from './components/TournamentContext'; 
import { TournamentProvider } from './components/TournamentContext';
import 'react-toastify/dist/ReactToastify.css';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, WorkplaceShareButton, WorkplaceIcon, XIcon } from 'react-share'; 
import { toast, ToastContainer } from 'react-toastify';
import useScrollRestoration from './useScrollRestoration';


const base_url = config.base_url;



//Initialize GA4 --> G-CGMYJ4SECK
ReactGA.initialize("G-CGMYJ4SECK");

function Header() {

  useScrollRestoration(); 
  const match = useMatch("/:linkindex/*") 
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { linkindex,setTournamentID } = useTournament();
  const { setLinkIndex } = useTournament();
  const [linktournament, setlinkTournament] = useState([]);
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const isHomepage = location.pathname === '/';
  const isContactpage = location.pathname === '/contact';
  const isFeedbackpage = location.pathname === '/feedback';
  //const [linkindex] = useState('');
  var isTournamentHomePage = true;
  var isAdminPage = false;
  
 


  const handleMenuStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const isCurrentPage = (path) => {
    
    return location.pathname === path; 
  };


  
    if(match?.params['*']){
     isTournamentHomePage = false;
    }

    if(match?.params['linkindex']==='admin'){
        isAdminPage=true;
    }

  useEffect(() => {

    if (match) {
      if (match.params.linkindex === 'admin') {
        const adminlinkindex = match.params['*'].split('/')[0];
        setLinkIndex(adminlinkindex);
        fetchTournamentbylinkindex(adminlinkindex);
      } else {
        const newLinkIndex = match.params.linkindex;
        if (newLinkIndex && newLinkIndex !== linkindex) {
          setLinkIndex(newLinkIndex);
          fetchTournamentbylinkindex(newLinkIndex);
        }
      }
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as per your needs
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [match]);


  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [location]);


  const fetchTournamentbylinkindex = async (linkindex) => {
    try {
      console.log(linkindex);
      if(linkindex != "add-tournament" && linkindex != "contact"  && linkindex != "sitemap.xml"  && linkindex != "feedback" ){

        const response = await axios.get(base_url+`/api/tournaments/${linkindex}`);
        if (response.data === null){
          alert('Invalid Link!');
          window.location.href = '/'; 
        } else {
         
        setlinkTournament(response.data);
        setTournamentID(response.data._id);
        
        }

      }
        
        
    } catch (error) {
      console.error(error);
      alert('Error!' + error);
      window.location.href = '/'; 
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success('Copied to clipboard');
      handleShare('CopyToClipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy');
    });
  };


  const handleShare = async (platform) => {
    try {
      await axios.post(base_url + '/api/share', {
        url: currentUrl,
        platform: platform
      });
      console.log(`Share count updated for ${platform}`);
    } catch (error) {
      console.error('Error updating share count:', error);
    }
  };


  return (
    <div>
       {isMobile && !isHomepage && !isAdminPage && !isContactpage  && !isFeedbackpage ? (
        <footer className="mobile-menu">
          {/* <Link to="/teams" onClick={closeMenu} className={isCurrentPage('/teams') ? 'selected-menu-item' : ''}>
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Teams
          </Link> */}
          <Link to={`/${linkindex}/schedules`} onClick={closeMenu} className={isCurrentPage(`/${linkindex}/schedules`) ? 'selected-menu-item' : ''}>
            <FontAwesomeIcon icon={faCalendarPlus} />
            &nbsp; Schedules
          </Link>
          <Link to={`/${linkindex}/scores`} onClick={closeMenu} className={isCurrentPage(`/${linkindex}/scores`) ? 'selected-menu-item' : ''}>
            <FontAwesomeIcon icon={faTrophy} />
            &nbsp; Score
          </Link>
          <Link to={`/${linkindex}/standings`} onClick={closeMenu} className={isCurrentPage(`/${linkindex}/standings`) ? 'selected-menu-item' : ''}>
            <FontAwesomeIcon icon={faRankingStar} />
            &nbsp; Standings
          </Link>
          <Link to={`/${linkindex}/wildcard`} onClick={closeMenu} className={isCurrentPage(`/${linkindex}/wildcard`) ? 'selected-menu-item' : ''}>
            <FontAwesomeIcon icon={faShuffle} />
            &nbsp; WildCard
          </Link>
          <Link to={`/${linkindex}/playoff`} onClick={closeMenu} className={isCurrentPage(`/${linkindex}/playoff`) ? 'selected-menu-item' : ''}>
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Playoff
          </Link>
        </footer>
      ) : !isHomepage && !isContactpage && !isFeedbackpage?  (
        <header className="desktop-header">
        <button className="menu-button" onClick={() => setIsMenuOpen(true)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <Menu
          isOpen={isMenuOpen}
          onStateChange={handleMenuStateChange}
          left
          disableAutoFocus
          width="200px"
          customBurgerIcon={false}
          customCrossIcon={false}
          itemListClassName="menu-links"
          burgerButtonClassName="burger-button"
        >
          <Link to={`/${linkindex}/`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faHome} />
            &nbsp; Home
          </Link>
          <Link to={`/${linkindex}/teams`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Teams
          </Link>
          <Link to={`/${linkindex}/schedules`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faCalendarPlus} />
            &nbsp; Schedules
          </Link>
          <Link to={`/${linkindex}/scores`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faTrophy} />
            &nbsp; Score
          </Link>
          <Link to={`/${linkindex}/standings`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faRankingStar} />
            &nbsp; Standings
          </Link>
          <Link to={`/${linkindex}/wildcard`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faShuffle} />
            &nbsp; WildCard
          </Link>
          <Link to={`/${linkindex}/playoff`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Playoff
          </Link>
          <Link to={`/${linkindex}/feedback`} onClick={closeMenu}>
            <FontAwesomeIcon icon={faComments} />
            &nbsp; Feedback
          </Link>
        </Menu>
        </header>
      ): null}
    <header className="app-header">
   
  <div className="header-div"> 
   
  {isHomepage ? (<h1 className="rainbow-text">  
       <a href="/"> <FontAwesomeIcon icon={faVolleyball} spin pulse /> 
        &nbsp; VolleyTally  &nbsp;
          <FontAwesomeIcon icon={faVolleyball} spin pulse />
          </a>
    </h1>) : (  <h1 className="rainbow-text"> 
       <a href="/"> <FontAwesomeIcon icon={faVolleyball} spin pulse /> 
        &nbsp; VolleyTally  &nbsp;
          <FontAwesomeIcon icon={faVolleyball} spin pulse />
          </a>
           <br/>
          <br/>
          {linktournament?.acronym?.toUpperCase()} &nbsp; {linktournament?.year}
    </h1>)}

    {/* <div className="under-construction-banner">
    <FontAwesomeIcon icon={faPersonDigging} beat  /> 
        &nbsp; Under Construction &nbsp;
          <FontAwesomeIcon icon={faPersonDigging}  beat />
        </div> */}

        
 
{ isTournamentHomePage 
  ? (isHomepage 
      ? null 
      : (<Link to={`/`} className="header-button">
          <FontAwesomeIcon icon={faHome} /> &nbsp; Home
        </Link>)
    ) 
  : (isAdminPage 
      ? (<Link to={`/admin/${linkindex}/admin`} className="header-button">
          <FontAwesomeIcon icon={faHome} /> &nbsp; Admin Home
        </Link>)
      : (<Link to={`/${linkindex}`} className="header-button">
          <FontAwesomeIcon icon={faHome} /> &nbsp; Home
        </Link>)
    )
}        
     
    </div>
    </header>


    <main className="app-main">
        <MainRoutes />
       
        </main>
       
        <footer  className="app-footer">
         
         {!isContactpage ? (   <div>         
      <br/>   
      Share this page 
      <br/>
                   
        <TwitterShareButton
                url={`${currentUrl}`}
                title={"Checkout Live Volleyball Score on VolleyTally"}
                hashtag={"volleytally"}
                onClick={() => handleShare('Twitter')}
              >
                <XIcon size={40} round  />
              </TwitterShareButton>
              &nbsp;
              <FacebookShareButton
                url={`${currentUrl}`}
                title={"Checkout Live Volleyball Score on VolleyTally"}
                hashtag={["volleyball", "volleytally"]}
                onClick={() => handleShare('Facebook')}
              >
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              &nbsp;
              <WhatsappShareButton

                url={`${currentUrl}`}
                title={"Checkout Live Volleyball Score on VolleyTally"}
                hashtag={["volleyball", "volleytally"]}
                onClick={() => handleShare('Whatsapp')}
              >
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
              &nbsp;&nbsp;


              <FontAwesomeIcon icon={faCopy} size="3x" onClick={copyToClipboard} />

              <br/>
              <br/>
               Request hosting your tournament
          <h2 className='home-button'>
          <Link to="./contact" >
            <FontAwesomeIcon icon={faComments} />
            &nbsp; Contact From
          </Link>  
          </h2></div>):(<div></div>) }
         <br/>
           <p> © OpenFirst Tech 2023. All rights reserved. Developed by <a href="https://mjzhub.com/" target="_blank"> Maulik Zalavadia </a> 
                with help of <a href="https://openai.com/blog/chatgpt" target="_blank">ChatGPT</a>.  
                <a href = "https://www.linkedin.com/in/mjzhub/" target="_blank"> Reach out </a> for collaboration. &#10084; </p>
        
              
        </footer>
        
    </div>
    
  );
}



const App = () => {


  return (
    <TournamentProvider >
    <Router>
      <div className="app-container">
     
      <Header/>
      
       
      </div>
    </Router>
    </TournamentProvider>

  );
};

export default App;
