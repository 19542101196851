import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { useTournament } from './TournamentContext';


const base_url = config.base_url;

const AddScore = () => {
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [sortedColumn, setSortedColumn] = useState('time');
  const [sortOrder, setSortOrder] = useState('asc');
  const [gameScores, setGameScores] = useState({});
  const [timeFilter, setTimeFilter] = useState('');
  const [allGameTimes, setAllGameTimes] = useState([]);
  const { tournamentID } = useTournament();

  
  useEffect(() => {
    if (tournamentID) {
      fetchDivisions();
      fetchTeams();
      fetchGames();
    }
  }, [tournamentID, selectedDivision, selectedTeam]);
  
  useEffect(() => {
    if (games.length > 0) {
      // Extract unique time values from games array
      const uniqueTimes = [...new Set(games.map((game) => game.time))];
      setAllGameTimes(uniqueTimes);
    }
  }, [games]);

  const fetchGames = async () => {
    try {
      let url;

      if(selectedTeam === '' && selectedDivision === ''){
        url = base_url + `/api/games/${tournamentID}`;
      } 
      if (selectedTeam === '' && selectedDivision != '') {
        url = base_url + `/api/games/division/${tournamentID}/${selectedDivision}`;
      }
      if (selectedTeam != '' && selectedDivision === ''){
        url = base_url + `/api/games/team/${tournamentID}/${selectedTeam}`;
      } 
      if (selectedTeam != '' && selectedDivision != ''){
        url = base_url + `/api/games/team/${tournamentID}/${selectedTeam}`;
      }
      const response = await axios.get(url);
      setGames(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTeams = async () => {
    try {
      let url;
      if (selectedDivision === '') {
        url = base_url + `/api/teams/${tournamentID}`;
      } else {
        url = base_url + `/api/teams/${tournamentID}/division/${selectedDivision}`;
      }
      const response = await axios.get(url);
      setTeams(response.data);
      if (selectedTeam === '' && response.data.length > 0) {
        setSelectedTeam(''); // Set the selected team to empty for "All" option
      }
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchDivisions = async () => {
    try {
        const response = await axios.get(base_url + `/api/divisions/${tournamentID}`);
        setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };


  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
    setSelectedTeam('');
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const handleFilterTime = (selectedTime) => {
    setTimeFilter(selectedTime);
  };



  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortOrder('asc');
    }
  };

  const handleScoreChange = (e, gameId, team) => {
    const { value } = e.target;
    setGameScores((prevGameScores) => ({
      ...prevGameScores,
      [gameId]: {
        ...prevGameScores[gameId],
        [`scoreTeam${team}`]: value,
      },
    }));
  };

  function tConvert (time) {
    if(time){
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      
    }
    return time.join (''); // return adjusted time or original string
    }

  }

  const updateAllScores = async () => {
    try {
      const updatedGames = await Promise.all(
        games.map(async (game) => {
          const { scoreTeam1, scoreTeam2 } = gameScores[game._id] || {};
          if(scoreTeam1 && scoreTeam2)
          {
            const response = await axios.put(base_url+`/api/games/${game._id}`, {
              scoreTeam1,
              scoreTeam2,
            });
            return response.data;
          }         
        })
      );

      await axios.put(base_url+`/api/teamstanding/${tournamentID}`, { });
      //setGames(updatedGames);
      fetchGames();
      setGameScores({});
      alert('Score Updated successfully!');
    } catch (error) {
      console.error('Failed to update game scores', error);
      alert('Error: '+ error.response.data.error);
    }
  };

  let sortedGames = [...games];
  
  const sortGamesByTime = (games) => {
    return games.sort((a, b) => {

      if (a.time < b.time) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a.time > b.time) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  
  if (sortedColumn === 'time') {
    sortedGames = sortGamesByTime(sortedGames);
  } else if (sortedColumn) {
    sortedGames.sort((a, b) => {
      const valueA = a[sortedColumn].name.toLowerCase();
      const valueB = b[sortedColumn].name.toLowerCase();
      if (valueA < valueB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  const filteredGamesByTime = sortedGames.filter((game) =>
    game.time?.toLowerCase().includes(timeFilter?.toLowerCase())
  );

  return (
    <div>
      <h2>Games</h2>
      <div>
        <label>
          Filter by Division:
          <select value={selectedDivision} onChange={handleDivisionChange}>
          <option value="">All Divisions</option>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label><br />
        <br />
        <label>
          Filter by Team:
          <select value={selectedTeam} onChange={handleTeamChange}>
          <option value="">All Teams</option>
            {teams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </label><br />
        <br />

        <div>
        <label>
          Filter by Time:
          <select value={timeFilter} onChange={(e) => handleFilterTime(e.target.value)}>
            <option value="">All</option>
            {allGameTimes.map((time) => (
              <option key={time} value={time}>
                {tConvert(time)}
              </option>
            ))}
          </select>
        </label>
      </div>
      <br />
        <br />
      </div>
      <div>
        <table className="schedule-table">
          <thead>
            <tr>
              <th
                className={sortedColumn === 'time' ? `sortable ${sortOrder}` : 'sortable'}
                onClick={() => handleSort('time')}
              >
                Time
              </th>
              <th
                className={sortedColumn === 'team1' ? `sortable ${sortOrder}` : 'sortable'}
                onClick={() => handleSort('team1')}
              >
                Team 1
              </th>
              <th
                className={sortedColumn === 'team2' ? `sortable ${sortOrder}` : 'sortable'}
                onClick={() => handleSort('team2')}
              >
                Team 2
              </th>
              <th>
                Team 1 Score
              </th>
              <th>
                Team 2 Score
              </th>
              <th>
                Update Team 1 Score
              </th>
              <th>
                Update Team 2 Score
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredGamesByTime.map((game) => (
              <tr key={game._id}>
                <td>{tConvert(game.time)}</td>
                <td>{game.team1.name}</td>
                <td>{game.team2.name}</td>
                <td>{game.scoreTeam1}</td>
                <td>{game.scoreTeam2}</td>
                <td>  
                  <input
                    type="number"
                    value={gameScores[game._id]?.scoreTeam1 || ''}
                    onChange={(e) => handleScoreChange(e, game._id, 1)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={gameScores[game._id]?.scoreTeam2 || ''}
                    onChange={(e) => handleScoreChange(e, game._id, 2)}
                  />                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button onClick={updateAllScores}>Update All</button>
        </div>
      </div>
    </div>
  );
};

export default AddScore;
