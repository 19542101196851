import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useTournament } from './TournamentContext';
//import './../styles.css'; // Import your CSS file

const base_url = config.base_url;

const Share = () => {
  const [shareList, setShareList] = useState([]); // Initialize with an empty array
  const [shareCount, setShareCount] = useState([]); // Initialize with an empty array




  useEffect(() => {
    
    fetchShareCount();
    fetchShareList();
  
  }, []);

  const fetchShareCount = async () => {
    try {
      const response = await axios.get(base_url + `/api/share-count-by-platform/`);
      setShareCount(response.data);
    } catch (error) {
      console.error('Failed to fetch Share', error);
    }
  };

  const fetchShareList = async () => {
    try {
      const response = await axios.get(base_url + `/api/share/`);
      setShareList(response.data);
    } catch (error) {
      console.error('Failed to fetch Share', error);
    }
  };

 

  return (
    <div className="feedback-container">
      <h1>  <FontAwesomeIcon icon={faComments} size="1x"/> &nbsp; Share</h1>
      <br/>
      <h3>Share Count.</h3>
      <br />
      <br />
      <div className="feedback-list">
        <h2>Share Count:</h2>
        <table>
          <thead>
            <tr>
              <th>Platform</th>
              <th>Count</th>
            </tr>
          </thead>
              <tbody>
                {shareCount.map((shareCount) => (
                  <tr key={shareCount.paltfrom}>
                    <td>{shareCount.platform}</td>
                    <td>{shareCount.count}</td>
                  </tr>
                ))}
              </tbody>
      </table>
      <br/>
      <h3>Share List.</h3>
      <br />
      <br />
        <h2>Share List:</h2>
        <table>
          <thead>
            <tr>
              <th>Platform</th>
              <th>URL</th>
            </tr>
          </thead>
              <tbody>
                {shareList.map((shareList) => (
                  <tr key={shareList._id}>
                    <td>{shareList.platform}</td>
                    <td>{shareList.url}</td>
                  </tr>
                ))}
              </tbody>
      </table>
          </div>
      </div>
  );
};

export default Share;
