import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy, faMedal } from '@fortawesome/free-solid-svg-icons';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";

const base_url = config.base_url;

const UpdateTournament = () => {
const [tournaments, setTournaments] = useState([]);
const [teams, setTeams] = useState([]);

const [name, setUpdatedNames] = useState({});
const [acronym, setacronymNames] = useState({});
const [location, setLocation] = useState({});
const [year, setYear] = useState({});
const [description, setDescription] = useState({});
const [winner, setWinner] = useState({});
const { tournamentID } = useTournament();
const [isPublished, setIsPublished] = useState(false);
const [hasIsPublishedChanged, sethasIsPublishedChanged] = useState(false);



  useEffect(() => {
    if(tournamentID)
    {
      fetchTournaments();
      fetchTeams();
    }
  }, [tournamentID]);

  useEffect(() => {
    // Initialize isPublished state based on fetched tournaments
    const initialState = {};
    tournaments.forEach((tournament) => {
      initialState[tournament._id] = tournament.isPublished;
    });
    setIsPublished(initialState);
  }, [tournaments]);

  const fetchTournaments = async () => {
    try {
      const response = await axios.get(base_url + `/api/tournaments/id/${tournamentID}`);
      setTournaments(response.data);
    } catch (error) {
      console.error('Failed to fetch tournaments', error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get(base_url + `/api/teams/${tournamentID}`);
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const handleTournamentNameChange = (e, tournamentId) => {
    setUpdatedNames((prevNames) => ({
      ...prevNames,
      [tournamentId]: e.target.value,
    }));
  };

  const handleAcronymNameChange = (e, tournamentId) => {
    setacronymNames((prevNames) => ({
      ...prevNames,
      [tournamentId]: e.target.value.toUpperCase(),
    }));
  };

  const handleLocationChange = (e, tournamentId) => {
    setLocation((prevNames) => ({
      ...prevNames,
      [tournamentId]: e.target.value,
    }));
  };
  
  
  const handleYearChange = (e, tournamentId) => {
    setYear((prevNames) => ({
      ...prevNames,
      [tournamentId]: e.target.value,
    }));
  };

  const handleDescriptionChange = (e, tournamentId) => {
    setDescription((prevNames) => ({
      ...prevNames,
      [tournamentId]: e.target.value,
    }));
  };

  
  const handleWinnerChange = (e, tournamentId) => {
    setWinner((prevNames) => ({
      ...prevNames,
      [tournamentId]: e.target.value,
    }));
  };
  
  const handleIsPublishedChange = (e, tournamentId) => {
    const isChecked = e.target.checked;
    if(isChecked !== isPublished[tournamentId]) {
      sethasIsPublishedChanged(true)
    };
   // sethasIsPublishedChanged(true);
    setIsPublished((prevIsPublished) => ({
      ...prevIsPublished,
      [tournamentId]: isChecked,
    }));
  };
    

  const handleUpdateTournament = async () => {
    try {
      const updatedTournaments = await Promise.all(
        tournaments.map(async (tournament) => {
          const updatedName = name[tournament._id];
          const updatedAcronym = acronym[tournament._id];
          const updatedLocation = location[tournament._id];
          const updatedYear = year[tournament._id];
          const updatedDescription = description[tournament._id];
          const updatedWinner = winner[tournament._id];
          const updateIsPublished = isPublished[tournament._id];       
        
          if (updatedName || updatedLocation || updatedAcronym || updatedYear || updatedDescription || updatedWinner || hasIsPublishedChanged) {
            const response = await axios.put(`${base_url}/api/tournaments/${tournament._id}`, {
              name: updatedName || tournament.name,
              acronym: updatedAcronym || tournament.acronym,
              location: updatedLocation || tournament.location,
              year: updatedYear || tournament.year,
              description: updatedDescription || tournament.description,
              winner: updatedWinner || tournament.winner,
              isPublished: updateIsPublished ,
            });
            return response.data;
           
          }
        })
      );
  
      alert('tournaments updated successfully!');
    } catch (error) {
      alert('Error: tournaments not updated.');
      console.error('Failed to update tournaments', error);
    }
  };
  

  return (
    <div>
      <table>
        <thead>
          <tr>
          <th>Tournament Linkindex</th>
            <th>Tournament Name</th>
            <th>Acronym</th>
            <th>Year</th>
            <th>Location</th>
            <th>Description</th>
            <th>Winner</th>
            <th>isPublished</th>
          </tr>
        </thead>
            <tbody>
              {tournaments.map((tournament) => (
                <tr key={tournament._id}>
                    <td>
                        {tournament.linkindex}
                    </td>
                  <td>
                    <input
                      type="text"
                      value={name[tournament._id] || tournament.name}
                      onChange={(e) => handleTournamentNameChange(e, tournament._id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={acronym[tournament._id] || tournament.acronym}
                      onChange={(e) => handleAcronymNameChange(e, tournament._id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={year[tournament._id] || tournament.year}
                      onChange={(e) => handleYearChange(e, tournament._id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={location[tournament._id] || tournament.location}
                      onChange={(e) => handleLocationChange(e, tournament._id)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={description[tournament._id] || tournament.description}
                      onChange={(e) => handleDescriptionChange(e, tournament._id)}
                    />
                  </td>
                  <td>
                      <select value={winner[tournament._id] || tournament.winner} onChange={(e) => handleWinnerChange(e, tournament._id)}>
                        <option value="">Select Team</option>
                        {teams.map((team) => (
                            <option key={team._id} value={team._id}>
                                {team.name}
                            </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={isPublished[tournament._id]}
                      onChange={(e) => handleIsPublishedChange(e, tournament._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>

      </table>
      <button onClick={handleUpdateTournament}>Update</button>


      <h2 className="home-button">
          <Link to="/admin/add-division" >
            <FontAwesomeIcon icon={faPlusSquare} />
            &nbsp; Add Division
          </Link>
          <br />
          <br />
          <Link to="/admin/add-team" >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Add Team
          </Link>
          <br />
          <br />
          <Link to="/admin/update-team" >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Update Team
          </Link>
          <br />
          <br />
          <Link to="/admin/add-game" >
            <FontAwesomeIcon icon={faGamepad} />
            &nbsp; Add Game
          </Link>
          <br />
          <br />
          <Link to="/admin/upload-game" >
            <FontAwesomeIcon icon={faGamepad} />
            &nbsp; Upload Game
          </Link>
          <br />
          <br />
          <Link to="/admin/add-score" >
            <FontAwesomeIcon icon={faTrophy} />
            &nbsp; Add Score
          </Link> 
          <br />
          <br />  
          <Link to="/admin/add-playoff" >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Add Playoff
          </Link>   
          <br />
          <br />  
          <Link to="/admin/add-playoffGame" >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Add New Playoff Game
          </Link>     
      </h2>
    </div>
  );
};

export default UpdateTournament;
