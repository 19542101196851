import loaderio from '../documents/loaderio-f566d198e1c407955b1d6cabdfd17307.txt'
import { useState, useEffect } from 'react';
import axios from 'axios';

const Documents = () => {
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    const fetchTextFile = async () => {
      try {
        const response = await axios.get('../../public/loaderio-f566d198e1c407955b1d6cabdfd17307.txt');
        setFileContent(response.data);
      } catch (error) {
        console.error('Error fetching text file:', error);
      }
    };

    fetchTextFile();
  }, []);

  return (
    <div>
      <pre>{fileContent}</pre>
    </div>
  );
};

export default Documents;
